<template>
  <div>

    <div class="well">
      <form
        autocomplete="off"
        class="form-horizontal"
        @submit.prevent="validateBeforeSubmit"
      >
        <div class="form-group row">
          <label
            class="col-form-label col-md-4"
            for="email"
          >Email:</label>

          <div
            class="col-md-8"
            :class="{ 'has-error' : errors.has('email') }"
          >
            <input
              autocomplete="off"
              name="email"
              v-model="user.email"
              v-validate
              data-vv-rules="required|email"
              class="form-control"
              type="email"
              id="email"
              placeholder="Email"
              :class="{ 'has-error' : errors.has('email') }"
            >
            <span
              v-show="errors.has('email')"
              class="text-danger"
            >
              {{ errors.first('email') }}
            </span>
          </div>
        </div>

        

        <div
          class="form-group row"
        >
          <label
            class="col-form-label col-md-4"
            for="password"
          >Password:</label>

          <div
            class="col-md-8"
            :class="{ 'has-error' : errors.has('password') }"
          >
            <input
              autocomplete="off"
              name="password"
              v-model="user.password"
              v-validate
              data-vv-rules="required|min:4"
              class="form-control"
              type="password"
              id="password"
              placeholder="Password"
              :class="{ 'has-error' : errors.has('password') }"
            >
            <span
              v-show="errors.has('password')"
              class="text-danger"
            >
              {{ errors.first('password') }}
            </span>
          </div>
        </div>

        

        <hr />

        <button
          type="submit"
          class="btn btn-danger btn-block"
          v-html="btnText"
        >
        </button>
      </form>
    </div>

  </div>
</template>

<script>
  import {mapActions} from 'vuex';
  export default {
    name: 'user-form',
    props: {
      user: {
        type: Object,
        required: true
      },
      isLogin: {
        type: Boolean
      },
      isRegister: {
        type: Boolean
      },
      isProfile: {
        type: Boolean
      },
      btnText: {
        type: String,
        required: true
      }
    },
    data () {
      return {
        error: null
      }
    },
    methods: {
      validateBeforeSubmit () {
        this.$validator.validateAll().then(result => {
          if ( ! result) {
            //hay errores
          } else {
            this.$emit('processUserForm', this.user);
          }
        })
      }
    }
  }
</script>
