<template>
  <div class="agendamiento">
    <div class="">
      <h4>Revisión final de calificaciones:</h4>
    </div>
    <div class="">
      <div v-if="progreso">
      	<div class="row">
	    	<div class="col-md-3">
	    		<strong>Tema</strong>		
	    	</div>
	    	<div class="col-md-3">
	    		<strong>Nota</strong>		
	    	</div>
	    	<div class="col-md-3">
	    		<strong>Acumulado</strong>		
	    	</div>
	    </div>
      	<div v-for="progreso_fila in progreso.progresos" >
      		<ClasesDetalleNotasRegistro @recargar="recargar" :registro="progreso_fila" />
      	</div>
      	<div class="row">
	    	<div class="col-md-3">
	    		<strong>Nota Final</strong>		
	    	</div>
	    	<div class="col-md-3">
	    		<strong></strong>		
	    	</div>
	    	<div class="col-md-3">
	    		<strong>{{notaFinal}}/5.0</strong>		
	    	</div>
	    </div>
      </div>
     
    </div>
  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  import TutoriaDetalleNotasRegistro from '@/components/Tutoria/TutoriaDetalleNotasRegistro';

  export default {
    name: 'TutoriaDetalleNotas',
    components:{
      TutoriaDetalleNotasRegistro
    },
    data () {
      return {
        texto: ''
      }  
    },
    props: {
      agendamiento: Object
    },
    mounted () {
      if(this.agendamiento){
      	this.recargar();
      }else{
      	console.log("Asegurese de usar este componente con un agendamiento.")
      }
      
    },
    methods:{
      ...mapActions({
      	fetchProgreso: 'progresos/fetchProgreso',
      }),
      ...mapMutations({
      }),
      recargar(){
        this.fetchProgreso({estudiante_id:this.agendamiento.estudiante_id})
      },
      calificado(){
        this.$emit('calificado')
      }
      
    },
    computed: {
      ...mapState({
      }),
      ...mapGetters({
      	getProgreso: 'progresos/getProgreso',
      }),
      progreso(){
        if(this.agendamiento){
          return this.getProgreso(this.agendamiento.estudiante_id)
        }
        return null
      },
      notaFinal(){
      	if(this.progreso && this.progreso.progresos){
      		let temasConNotas = this.progreso.progresos.filter(element=>{
	      		return element.rango_nota && element.peso_nota
	      	})
      		let notaF = 0;
      		for (let i in temasConNotas){
      			let tema = temasConNotas[i]
      			let notaParcial = (tema.nota / tema.rango_nota) * tema.peso_nota
            notaParcial = notaParcial.toPrecision(3)*1
            notaParcial = notaParcial*5/100
            notaF += notaParcial
      			
      		}

	      	return notaF.toPrecision(3);	
      	}
      	
      	return null
      }
      
    }
  }
</script>

<style scope>

</style>