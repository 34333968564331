<template>
  <div class="row">
    <div class="col-12">
      <strong>Observaciones:</strong>
    </div>
    <div class="col-12 observaciones">
      <div class="observacion m-0 pt-1" v-for="observacion in observaciones">
        <p class="m-0">{{ observacion.texto}}
          <span class="muted">  {{ observacion.usuario}} / {{ observacion.fecha}}</span>
        </p>
      </div>
      <div v-if="observando">
        <textarea placeholder="escriba aquí" v-model="texto">{{ texto }}</textarea><br>
        <button @click="guardar" class="btn btn-success btn-sm mr-3" >Guardar</button>
        <button @click="cancelar" class="btn btn-danger btn-sm" >Cancelar</button>
      </div>
      <div v-if="puedeAgregar">
        <button @click="observando=true" class="btn btn-warning btn-sm" >Agregar</button>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  
  export default {
    name: 'TutoriaDetalleObservaciones',
    
    data () {
      return {
        observando : false,
        texto: ''
      }  
    },
    props: {
      row: Object
    },
    mounted () {
      
    },
    methods:{
      ...mapActions({
      }),
      ...mapMutations({
      }),
      cancelar(){
        this.texto=''
        this.observando=false
      },
      guardar(){
        this.$emit('agregarObservacion', {texto:this.texto,id:this.row.id});
        this.texto=''
        this.observando=false
      },

    },
    computed: {
      ...mapState({
      }),
      ...mapGetters({
      }),
      observaciones(){
        return JSON.parse(this.row.observaciones)
      },
      puedeAgregar(){
        if(this.observando){
          return false
        }
        if(this.row.asistencia){
          return false
        }
        return true
      }

      
    }
  }
</script>

<style scope>
.observaciones .muted{
  font-size: 8px;
  color:#999;
}
.observaciones .observacion{
  margin-bottom: 10px;
}
.observaciones .observacion p{
    font-size: 12px;
    line-height: 12px;
}
</style>