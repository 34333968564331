<template>
  <div class="container mt-3">
    <h1 class="text-center text-muted">Tutorias</h1>
    <hr>
    <div v-if="formattedTutorias" class="tabla">
      <v-client-table @row-click="rowClick" name="clasesTable" :columns="columns" :data="formattedTutorias" :options="options">
        
        <div slot="fecha" slot-scope="formattedTutorias">{{ formattedTutorias.row.fecha }}<br>{{ textoHora(formattedTutorias.row.hora) }}</div>

        <div slot="salon" slot-scope="formattedTutorias" v-if="formattedTutorias.row.salon_url"> <a :href="formattedTutorias.row.salon_url" target="_blank">{{  formattedTutorias.row.salon}}</a> </div>
        <div slot="salon" slot-scope="formattedTutorias" v-else>{{ formattedTutorias.row.salon }}</div>
        
        <div slot="nombresTipos" slot-scope="formattedTutorias" >
          <span>Tutoria </span>
        </div>

      </v-client-table>
    </div>
    <button class="btn btn-primary btn-sm" @click="recargar">Recargar</button>
  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  import utils from '@/modules/utils';
  export default {
    name: 'TutoriaLista',
    data () {
      return {
        columns: [ 'fecha', 'salon','nombresTipos'],
        options: {
          filterable: false,
          filterByColumn: false,
          sortable:[],
          perPage: 10,
          perPageValues: [5, 10, 15, 20],
          headings: {
            id: 'ID',
            nombresTipos: 'CLASE',
            fecha: 'FECHA',
            salon: 'SALON',
          }
        }
      }
    },
    props: {
    },
    mounted () {
        this.fetchTutorias()
    },
    methods:{
      ...mapActions({
        fetchTutorias: 'tutorias/fetchTutorias',
      }),
      ...mapMutations({

      }),
      recargar(){
        this.fetchTutorias().catch(()=>{}) 
      },
      textoHora(value){
        return utils.getTextoHora(value)
      },
      rowClick(event){
        this.$router.push('/tutoria/detalle/'+event.row.id)
      }
    },
    computed: {
      ...mapState({
        tutorias: state => state.tutorias.tutorias, 
      }),
      ...mapGetters({

      }),
      formattedTutorias(){
        let respuesta = [];
        if(this.tutorias && this.tutorias.length) {
          let ahora = new Date()
          this.tutorias.map(clase => {
            if(clase.id != null){
              let salon_txt = clase.salon != null?clase.salon.nombre:"";
              let salon_url = null; 
              if(clase.salon != null ?clase.salon.numero:""){
                salon_txt = clase.salon.numero+' '+salon_txt 
              }
              if(clase.salon && clase.salon.url){
                salon_url = clase.salon.url 
              }
              respuesta.push({
                  id: clase.id,
                  curso: clase.curso != null?clase.curso.nombre:clase.tipo_clase,
                  fecha: clase.fecha.split('T')[0],
                  hora: clase.hora,
                  salon: salon_txt,
                  salon_url: salon_url,
                  nombresTipos: clase.nombresTipos?clase.nombresTipos:[]

              }) 
            }
          });
        }
        return respuesta;
      }
    }
  }
</script>

<style scope>

</style>