<template>
  <div class="container mt-3">
    <div v-if="clase">
      <h3 class="text-center" v-if="salonUrl"><a :href="salonUrl" target="_blank">{{  titulo }}</a></h3>
      <h3 class="text-center" v-else>{{  titulo }}</h3>
      <h4 class="text-center cap">
        <span v-for="nombre in clase.nombresTipos">{{ nombre }} </span>
      </h4>
      <h5 class="text-center">{{ clase.fecha && clase.fecha.split('T')[0] }} / {{ textoHora(clase.hora) }}</h5>
    </div>
    <div v-if="clase">

      <TutoriaDetalleAgendamiento @recargar="recargar" v-for="(agendamiento, ix) in clase.estudiantes" :agendamiento="agendamiento" :key="ix"/>
      
    </div>
    <hr>
    <button class="btn btn-primary btn-sm" @click="recargar">Recargar</button>
  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  import TutoriaDetalleAgendamiento from '@/components/Tutoria/TutoriaDetalleAgendamiento';
  
  import utils from '@/modules/utils';

  export default {
    name: 'TutoriaDetalle',
    components: {
      TutoriaDetalleAgendamiento
    },
    data () {
      return {
        
        tutoria_id:null
      }
    },
    props: {
      
    },
    mounted () {
      this.tutoria_id = this.$route.params.id;
      this.fetchTutoria({tutoria_id:this.tutoria_id})
    },
    methods:{
      ...mapActions({
        fetchTutorias: 'tutorias/fetchTutorias',
        fetchTutoria: 'tutorias/fetchTutoria',
        saveAsistencia: 'clases/saveAsistencia',
        saveObservacion: 'clases/saveObservacion',
      }),
      ...mapMutations({

      }),
      recargar(){
        this.fetchTutoria({tutoria_id:this.tutoria_id})
      },
      textoHora(value){
        return utils.getTextoHora(value)
      }
    },
    computed: {
      ...mapState({
        tutorias: state => state.tutorias.tutorias, 
      }),
      ...mapGetters({
      }),
      clase(){
        if(!this.tutorias){
          this.fetchTutorias()
          return null
        }
        let cl = this.tutorias.find(element =>{
          return element.id == this.tutoria_id
        })
        if(!cl){
          return null
        }
        return cl
      },
      titulo(){
        let titulo = ''
        if (this.clase && this.clase.salon) {
          if(this.clase.salon.numero){
            titulo += this.clase.salon.numero
          }
          titulo += this.clase.salon.nombre
          if(this.clase.curso){
            titulo += ' / '+this.clase.curso.nombre
          }
        }
        return titulo
      },
      salonUrl() {
        if (this.clase && this.clase.salon && this.clase.salon.url) {
          return this.clase.salon.url;
        }
        return null;
      }
    }
  }
</script>

<style scope>
</style>