
<template>
  <div class="container  mt-3">
    <div class="m-auto">
    	
      <div class="form-horizontal" v-if="progreso">
        	<div class="form-group row" v-if="progreso.nota">
          		<strong class="col-3">Calificación:</strong>
          		<div class="col-3">{{progreso.nota}}</div>
          	</div>

          	<div class="form-group row" v-else>
	            <label class="col-3 col-form-label">Calificación</label>
	            <div class="col-4">
			          <input type="text"
			          v-model="nota"
			          name="Calificación"
			          :placeholder="rango"
			          class="form-control"
			          >
	            </div>
	            <div class="col-3">
	              <button v-if="procesable" class="btn btn-block btn-danger" @click="procesar">
	                Guardar Nota
	              </button>
	            </div>
	            <span>{{instrucciones}}</span>
          	</div> 
        
      </div>
    </div>
  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';

  export default {
    name: 'TutoriaIngresarNota',
    components: {
      
    },
    data () {
      return {
        progreso:null,
        nota:null
      }
    },
    props: {
      agendamiento: Object
    },
    mounted () {
      
      if(this.agendamiento){
        this.fetchDetalle(this.agendamiento.id).then((progreso)=>{
this.progreso = progreso
        })
      }else{
        console.log('Hay que asegurarse de tener un estudiante antes de utilizar el componente ClasesIngresarNota')
      }
    },
    methods:{
      ...mapActions({
        saveNota: 'progresos/saveNota',
        fetchDetalle: 'progresos/fetchDetalle',
        setWarning: 'setWarning',
      }),
      ...mapMutations({
        
      }),
      procesar(){
        if(this.nota>this.agendamiento.tema.rango_nota){
        	this.setWarning('El rango de calificación es de 0 a '+this.agendamiento.tema.rango_nota).then(()=>{
        		this.limpiar()
        	})
        	return
        }

        if(this.nota<0){
        	this.setWarning('El rango de calificación es de 0 a '+this.agendamiento.tema.rango_nota).then(()=>{
        		this.limpiar()
        	})
        	return
        }
        	
    	this.saveNota({
          agendamiento_id:this.agendamiento.id,
          nota:this.nota,
        }).then((apiResponse)=>{
          
          this.progreso = apiResponse
          this.limpiar()
          this.setWarning('Nota guardada.', { root: true }).then(()=>{
             
          })
          
          
        })
        
        
      },
      limpiar(){
        this.nota = null
      }
    },
    computed:{
      ...mapState({
      }),
      ...mapGetters({
        
      }),
      procesable: {
        get () {
          return (this.nota)
        },
        set(value){}
      },
      
      instrucciones(){
      	return 'Ingrese la calificación de el estudiante en el rango:'+this.rango
      },
      rango(){
      	return '0-'+this.agendamiento.tema.rango_nota
      }
    }
  }
</script>

<style scope>
  .programar_wrapper .form-container{
      
  }
	.programar_wrapper{
    
	}
</style>



