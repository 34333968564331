<template>
   <div id="app">
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
    <navigation></navigation>
    <div v-if="processing">
      <BlockUI :message="blockMessage"></BlockUI>
    </div>
    <div v-if="warning">
      <BlockUI :message="warning"></BlockUI>
    </div>
    <b-modal  ok-only v-model="upgrading"  id="modalupgrading" title="Actualización encontrada">
      <p>Nueva versión encontrada, para actualizar por favor cierre la aplicación e intente nuevamente</p>
    </b-modal>

    <b-modal  no-close-on-backdrop no-close-on-esc hide-header hide-footer ok-only 
    v-model="updateExists"  id="modalupgrading" >
      <div class="text-center">
        <h5>Encontramos una nueva versión de la aplicación, es necesario actualizar.</h5>
        <b-button variant="success" @click="refreshApp">
          Actualizar ahora
        </b-button>
      </div>
    </b-modal>
    

    
    <div v-if="error">
      <BlockUI :message="error"></BlockUI>
    </div>
    <router-view></router-view>
    <footer class="bd-footer text-muted pt-5 mt-5 text-center">
      <div class="container-fluid pt-5 mt-5">
        <p>Programador de clases para American School Way</p>
        <p>(Teacher) - Versión Actual {{ version }}</p>
        <!--b-button class="m-0 p-0" @click="verificarVersion(true)" size="sm" variant="link" >
        Verificar actualizaciones disponibles</b-button>
        <p v-if="hasNewVersion">Nueva versión encontrada: {{ reportedVersion }}</p-->
        <!--p>Diseñado y construido por Disarrollo Software SaS</p-->

        <div v-if="updateExists">
          Encontramos una actualizacion
          <b-button @click="refreshApp">
            Actualizar ahora
          </b-button>
        </div>

        <span>2018</span>
        <div>
          <div style="display: inline-block;">
            <div style="background-color: yellow;width: 48px;height: 6px"></div>
            <div style="background-color: blue;width: 48px;height: 3px"></div>
            <div style="background-color: red;width: 48px;height: 3px"></div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
  import {mapState, mapActions} from 'vuex';
  import Navigation from '@/components/Navigation.vue';  
  import utils from '@/modules/utils';
  export default {
    components: {
        Navigation,
    },
    name: 'app',
    data () {
      return {
        msg: 'Welcome to Your Vue.js App',
        blockMessage: 'Procesando...',
        upgrading: false,
        registration: null,
        updateExists: false,
      }
    },
    mounted(){
      // this.verificarVersion()  
    },
    created(){
      //Necesario para las actualizaciones de la aplicacion
      //Ejecuta el evento updateAvailable cuando se encuentre actualizaciones
      document.addEventListener('swUpdated', this.updateAvailable, { once: true })
    },
    methods: {
      ...mapActions({
        fetchVersion: 'fetchVersion',
        setWarning: 'setWarning',
      }),
      verificarVersion(isManual){
        this.fetchVersion().then(()=>{
          console.log(`${this.reportedVersion} => ${utils.VERSION}`)
          if(this.reportedVersion){
            if(this.reportedVersion != utils.VERSION){
              let upgrading_cookie = $cookies.get('RELOADING');
              if(upgrading_cookie){
                $cookies.remove('RELOADING');
                this.upgrading = true
              }else{
                this.reload()
              }
            }else{
              if(isManual){
                this.setWarning('Su aplicación está al día!')
              }
            }
          }
        })
      },
      // reload(){
      //   $cookies.set('RELOADING','TRUE',120);
      //   window.location.reload(true);
      // },
      reload(){
        window.location.reload(true);
      },
      updateAvailable(event) {
        this.registration = event.detail
        this.updateExists = true
      },
      refreshApp() {
        this.updateExists = false

        // Make sure we only send a 'skip waiting' message if the SW is waiting
        if (!this.registration || !this.registration.waiting) return
        
        //Permite la actualizacion del service worker
        this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })

        //Recarga la aplicacion
        window.location.reload(true);
      }
    },
    computed: {
      ...mapState({
        processing: state => state.processing,
        error: state => state.error,
        warning: state => state.warning,
        reportedVersion: state => state.version
      }),
      version(){
        return utils.VERSION
      },
      hasNewVersion(){
        if(this.reportedVersion){
          if(this.reportedVersion != utils.VERSION){
            return true
          }
        }
        return false
      }
    }
  }
</script>


<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.bg-red{
    background-color:#BC2336;
  }
.bg-blue{
    background-color:#0D4E8C !important;
}
hr {
  border: 1px solid #E33A2D !important;
  width: 100%;
}  
.bd-footer{background-color: #f7f7f7;font-size: 85%;}
.bd-footer p{
  margin:0px;
}
</style>
